.cd-container {
    color: white;
    width: 90%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 10%;
    border-radius: 2px;
}
.cd-container::after {
    content: '';
    display: table;
    clear: both;
}

/* -------------------------------- 

Main components 

-------------------------------- */

.cd-timeline-content {
    display: flex;
    flex-direction: column;

    .cd-timeline-content-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    img {
        width: 100px;
        object-fit: contain;
        border-radius: 15px;
    }

    h1 {
        margin: 0 auto;
        text-align: center;
        font-size: 16pt;
        padding: 0 5px;
    }

    h2 {
        margin-bottom: 10px;
    }

    .cd-timeline-content-description {
      display: flex;
      flex-direction: column;
      color: white;

      .tools {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        width: 89%;
        justify-content: center;

        img {
          height: 100px;
        }
      }
    }

    .cd-timeline-content-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .tools {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

        img {
          height: 50px;
          width: auto;
          object-fit: contain;
        }
      }
    }

    .cta-btn {
      padding: 10px;
      margin-top: 10px;
      text-align: center;
      border-radius: 5px;
      width: unset;
    }
}

.link-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(247, 247, 242);
  color: black;
  gap: 40px;
  padding: 60px 20px;

  h1 {
      text-align: center;
  }

  div {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .btn {
    padding: 10px;
    width: 200px;
    margin-top: 10px;
    text-align: center;
    border-radius: 5px;
  }

}


#cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
  // z-index: -1;
}
#cd-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 25px;
  height: 100%;
  width: 4px;
  background: #7E57C2;
}
@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.cd-timeline-block {
  position: relative;
  margin: 2em 0;
}
.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.cd-timeline-img {
  position: absolute;
  top: 8px;
  left: 12px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #7E57C2, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.cd-timeline-img {
  background: #673AB7;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 30px;
    height: 30px;
    left: 50%;
    margin-left: -15px;
    margin-top: 15px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
}

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  margin-right: 30px;
  background: rgb(75 74 74);
  border-radius: 15px;
  padding: 1em;
}
.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.cd-date {
    color: white;
}

.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid rgb(75 74 74);
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-content .cd-timeline-content-description {
    -webkit-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;

    .tools {
      opacity: 0;
      -webkit-transition: opacity 0.3s ease-in-out;
      -moz-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      -ms-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      color: rgb(75 74 74);
      -webkit-transition: color 0.3s ease-in-out;
      -moz-transition: color 0.3s ease-in-out;
      -o-transition: color 0.3s ease-in-out;
      -ms-transition: color 0.3s ease-in-out;
      transition: color 0.3s ease-in-out;

      .tools {
        opacity: 1;
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        -ms-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }
  .cd-date {
      color: #2B343A;
  }
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 36%;
    margin: 0 5%
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: rgb(75 74 74);
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: rgb(75 74 74);
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
}