$mobile-size: 950px;

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    background-color: rgb(247, 247, 242);
    color: rgba(#212326, 1);
    font-family: 'Noto Sans KR', sans-serif;
    overflow-x: hidden;
}

a {
    cursor: pointer;
}

.bold {
    font-weight: 500;
}

p {
    margin-bottom: 10px;
}

h1 {
    font-size: 25pt;
}

h2 {
    font-size: 16pt;
}

button, .btn {
    pointer-events: auto;
    cursor: pointer;
    background-color: rgb(75, 74, 74);
    color: #ffffff;
    border-radius: 5px;
    padding: 10px 10px;
    text-align: center;
    
    &:hover {
        background-color: rgba(75, 74, 74, 0.8);
    }
}

.cta-btn {
    background-color: #ffffff;
    color: rgb(75, 74, 74);
    width: 200px;
    
    &:hover {
        background-color: rgba(#ffffff, 0.8);
    }
}

p::after{
    content: "\a";
    white-space: pre;
}

.smart-image {
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    opacity: 1;

    img {
        opacity: 1;
    }

    .placeholder {
        position: absolute;
        width: 0px;
        height: 0px;
        opacity: 0;
        margin: 0 auto;
    }

    &.wait-image {
        opacity: 1;
        background-color: #eee;
        img {
            position: absolute;
            opacity: 0;
        }
        .placeholder {
            position: relative;
            width: 500px;
            height: 350px;
            opacity: 1;
        }
    }
}

@media screen and (max-width: $mobile-size) {
    .smart-image.wait-image .placeholder {
        width: 100%;
    }
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 4%, #dddddd 21%, #eeeeee 68%);
    background-size: 800px 104px;
    height: inherit;
    position: relative;
}

.donut {
    margin: 0;
}

.donut-container {
    margin-top: -30px;
}