$mobile-size: 950px;

.contact button {
    width: 175px;
    
    &:hover {
        background-color: rgb(75 74 74);
    }

    &.email-copy {
        background-color: rgba(112, 182, 115, 1.00);
        content: "Copied!";
        font-weight: bold;
    
        &:hover {
            background-color: rgba(112, 182, 115, 1.00);
        }
    }
}

.email {
    font-size: 20pt;
    font-weight: bold;
    background-color: white;
    color: rgba(48, 48, 48, 1);
    padding: 10px 20px;
    border: 1px solid rgba(48, 48, 48, 0.5);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}