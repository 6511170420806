$mobile-size: 950px;

.alt-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 60px 20px;
    background-color: rgb(75 74 74);
    color: white;
}

.alt-section-text {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

}

.job-reviews {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 20px 50px;
    gap: 20px;

    h1 {
        width: 100%;
        text-align: center;
        padding: 50px 20px 30px;
    }

    .review {
        max-width: 400px;
        gap: 5px;

        p {
            margin-bottom: unset;
        }
    }
}

@media screen and (max-width: $mobile-size) {
    .work {
        flex-direction: column;
    }

    .job-reviews {
        .review-title {
            padding: unset;
        }
    }
}