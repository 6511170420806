$mobile-size: 950px;

.home {
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-about {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 50px 5px;

  h2 {
    div {
      text-align: justify;
    }
  }
}

.home-title {
  max-width: 950px;
  padding: 50px 5px;
  // height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  h1 {
    margin: 20px;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
  }
}

.home-buttons {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  gap: 50px;

  .btn {
      width: 100px;
      border-radius: 5px;
      background-color: rgb(247, 247, 242);
      color: rgb(139, 139, 139);
      border: 2px solid rgb(219, 219, 216); 
      -webkit-transition: background-color 0.4s ease-in-out, color 0.3s ease-in-out;
      -moz-transition: background-color 0.4s ease-in-out, color 0.3s ease-in-out;
      -o-transition: background-color 0.4s ease-in-out, color 0.3s ease-in-out;
      -ms-transition: background-color 0.4s ease-in-out, color 0.3s ease-in-out;
      transition: background-color 0.4s ease-in-out, color 0.3s ease-in-out;

      &:hover {
          background-color: rgb(219, 219, 216); 
          color: rgb(111, 111, 111);
          -webkit-transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out;
          -moz-transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out;
          -o-transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out;
          -ms-transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out;
          transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out;
      }
  }
}

.websites {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 100px;

  .website {
    transition: transform .75s;

    img {
      width: 500px;
      height: 350px;
      border-radius: 5px;
    }

    &:hover {
      transform: scale(0.95);
    }
  }
}

@media screen and (max-width: $mobile-size) {
  .home-title {
    padding: 100px 5px 50px;
  }

  .btn {
    z-index: 1;
    margin-bottom: 30px;
  }

  .websites {
    width: 90%;
    padding: 0 5%;

    .website {
      width: 100%;

      img {
        width: 100%;
        height: unset;
      }
    }
  }
}