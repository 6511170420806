$mobile-size: 950px;

.pb-20 {
    padding-bottom: 20px;
}

.text-center {
    text-align: center;
}

.final-section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(#212326, 1);
    color: white;
    padding: 130px;
    gap: 40px;

    h1 {
        text-align: center;
    }

    button {
        width: 200px;
    }
}

@media screen and (max-width: $mobile-size) {

    .final-section {
        padding: 150px 80px;
    }
}