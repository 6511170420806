$mobile-size: 950px;
  
.review-title {
  color: #75808a;
  font-size: 23px;
  font-weight: 500;
  margin-top: 10px;
}

.review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 10px 100px;
  color: #75808a;
}

.quote {
  // content: '\201C';
  color: #81bedb;
  font-size: 17pc;
  font-weight: 700;
  opacity: .3;
  position: absolute;
  text-shadow: none;
  margin-top: -50px;
  margin-left: -100px;
}

cite {
  color: gray;
  display: block;
  font-size: .8em;

  .cite-name {
    color: #5e5e5e;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0 1px white;
  }
}

@media screen and (max-width: $mobile-size) {
  
  .review-title {
    padding: 20px 0;
  }

  .quote {
    margin-left: -30px;
    margin-top: 20px;
  }

  .review {
    margin: 20px 0 20px;
    padding: 0;
  }
}