$mobile-size: 950px;

.header-buffer {
    margin-top: 70px;
}

header {
    margin-top: -50px;
}

header {
    position: fixed;
    width: 100%;
    z-index: 10;

    font-size: 14px;

    .header-banner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 96%;
        max-width: 1000px;
        margin: 10px auto;
        padding: 5px 1%;

        background-color: rgb(219, 219, 216); 
        border-radius: 15px;

        .banner-logo {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 11;

            img {
                width: 25px;
                height: 25px;
                padding: 5px;
                cursor: pointer;
                pointer-events: auto;
            }
        }

        nav {
            display: flex;
            flex-direction: row;
            z-index: 12;
            gap: 10px;

            a {
                padding: 10px;
                border-radius: 10px;

                &:hover {
                    background-color: rgb(230, 230, 227);
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-size) {

    .header-buffer {
        margin-top: 50px;
    }

    header {
        display: flex;
        flex-direction: column;
        margin-top: -190px;
        gap: 10px;
        width: 92%;
        padding: 0 4%;

        nav {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            background-color: rgb(219, 219, 216);
            border-radius: 20px;

            a {
                padding: 10px 5%;
                width: 90%;
                text-align: center;
                border-radius: 20px;
            }

            a:hover {
                background-color: #f2f4f8;
            }
        }

        button {
            background-color: rgb(219, 219, 216);
            color: black;

            &:hover {
                background-color: rgb(219, 219, 216);
            }
        }
    }

    header.menu button.open {
        background-color: rgb(147, 147, 147);
        color: white;
    }

    .menu {
        transform: translate(0, 0);
        transition: transform 0.5s ease-in-out; // for the transition
    }

    .menu.open {
        transform: translate(0, 160px);
        transition: transform 0.5s ease-in-out; // for the transition
    }

    .home h2 {
        font-size: 16pt;
        text-align: center;
        padding: 0 30px;
    }
}